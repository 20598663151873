// Feature flagged over tab shellimport {

import { Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { LoadingIndicator, useToast } from "../../../../components";
import GeneralErrorIcon from "../../../../plan/pages/images/GeneralErrorIcon";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import {
  CandidateSummaryProcessingStatus,
  useCandidateSummaryLazyQuery,
  useCandidateSummaryTemplatesLazyQuery,
  useGenerateCandidateSummaryMutation,
} from "../../../graphql";
import { useUrlState } from "../../../pages/home/useTabUrlState";
import SummaryTabContentV2 from "./SummaryTabContentV2";

type SummaryTabV2Props = {
  candidateId: string;
  positionId?: string;
  notesExpanded: boolean;
  toggleNotes: () => void;
  innerExpandedWidth: string;
  summaryCallCount: number;
};

/**
 * Wrapper for V2 candidate summary tab.
 */
const SummaryTabV2: React.FC<SummaryTabV2Props> = ({
  candidateId,
  positionId,
  notesExpanded,
  toggleNotes,
  innerExpandedWidth,
  summaryCallCount,
}) => {
  const toast = useToast();
  /**
   * TODO: useCandidateSummary() hook that encapsulates
   * URL, loading the template, polling, and switching logic.
   */
  // Set and handle templates selectors
  const [getTemplates, { data: templates, loading: templatedLoading }] =
    useCandidateSummaryTemplatesLazyQuery({
      fetchPolicy: "cache-and-network",
    });
  useEffect(() => {
    getTemplates({ variables: { candidateId, positionId } });
  }, [candidateId, positionId]);
  const [currentUrlTemplateId, setUrlTemplateId] = useUrlState({
    key: "templateId",
  });
  const [selectedTemplate, setSelectedTemplate] = useState<string>(
    currentUrlTemplateId ?? ""
  );

  const handleChangeTemplate = (templateId: string): void => {
    if (templateId !== selectedTemplate) {
      setSelectedTemplate(templateId);
      setUrlTemplateId(templateId);
    }
  };
  useEffect(() => {
    if (selectedTemplate === "" && templates?.candidateSummaryTemplates?.[0]) {
      handleChangeTemplate(templates?.candidateSummaryTemplates?.[0].id);
    }
  }, [templates]);

  const [fetchSummary, aiCandidateSummary] = useCandidateSummaryLazyQuery();

  // original fetch with polling
  useEffect(() => {
    fetchSummary({
      variables: {
        candidateId,
        positionId,
        templateId: selectedTemplate,
      },
      pollInterval: 5000,
      fetchPolicy: "network-only",
    });
  }, [candidateId, positionId, selectedTemplate]);

  // effect check to turn off polling
  // check should only run when loading is done
  const aiSummaryStatus = aiCandidateSummary.data?.candidateSummary?.status;
  const aiSummaryLoading = aiCandidateSummary.loading;
  useEffect(() => {
    if (!aiSummaryLoading) {
      // stop polling if summary cannot be generated
      if (summaryCallCount === 0) {
        aiCandidateSummary.stopPolling();
      }
      // stop polling if summary is completed or failed
      if (
        aiSummaryStatus &&
        [
          CandidateSummaryProcessingStatus.Completed,
          CandidateSummaryProcessingStatus.Failed,
        ].includes(aiSummaryStatus)
      ) {
        aiCandidateSummary.stopPolling();
      }
    }
  }, [aiSummaryStatus, summaryCallCount, aiSummaryLoading]);

  const [generateCandidateSummary] = useGenerateCandidateSummaryMutation({
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
  });
  const sendGAEvent = useSendGAEvent();
  const generateSummary = (templateId: string): void => {
    sendGAEvent("candidate_summary_generate", "candidate");
    generateCandidateSummary({
      variables: {
        templateId,
        positionId,
        candidateId,
      },
    }).then(() => {
      fetchSummary({
        variables: {
          candidateId,
          positionId,
          templateId,
        },
        pollInterval: 5000,
        fetchPolicy: "network-only",
      });
    });
  };

  if (
    aiCandidateSummary.loading ||
    templatedLoading ||
    (!aiCandidateSummary.data && !aiCandidateSummary.error) // not fetched yet
  ) {
    return <LoadingIndicator />;
  }
  if (aiCandidateSummary.error) {
    return (
      <>
        <GeneralErrorIcon
          mb={4}
          mt={4}
          display="flex"
          justifyContent="center"
        />
        <Text
          fontSize="16px"
          mt="2"
          fontWeight={600}
          color="gray.200"
          textAlign="center"
        >
          There was an error loading this summary. Contact support for more
          info.
        </Text>
      </>
    );
  }

  return (
    <SummaryTabContentV2
      data={aiCandidateSummary.data?.candidateSummary}
      dataLoading={aiCandidateSummary.loading}
      templates={templates?.candidateSummaryTemplates || []}
      generateSummary={generateSummary}
      selectedTemplate={selectedTemplate}
      handleChangeTemplate={handleChangeTemplate}
      candidateId={candidateId}
      notesExpanded={notesExpanded}
      toggleNotes={toggleNotes}
      innerExpandedWidth={innerExpandedWidth}
      summaryCallCount={summaryCallCount}
      positionId={positionId || null}
    />
  );
};

export default SummaryTabV2;
