import { Tooltip } from "@chakra-ui/react";
import React from "react";
import { HiOutlinePencil } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import { IconButton } from "../../../../components";
import { getEditTemplateUrl } from "./utils";

type SummaryEditTemplateButtonProps = {
  currentTemplateId: string;
  candidateId: string;
  positionId: string | null;
};

const SummaryEditTemplateButton: React.FC<SummaryEditTemplateButtonProps> = ({
  currentTemplateId,
  candidateId,
  positionId,
}) => {
  const navigate = useNavigate();

  return (
    <Tooltip label="Edit template">
      <IconButton
        icon={<HiOutlinePencil />}
        aria-label="edit template"
        variant="icon"
        color="gray.900"
        onClick={() =>
          navigate(
            getEditTemplateUrl(currentTemplateId, candidateId, positionId)
          )
        }
      />
    </Tooltip>
  );
};

export default SummaryEditTemplateButton;
