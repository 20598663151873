import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, IconButton } from "../../../../components";
import { LeftArrow } from "../../../../components/Icons/LeftArrow";
import {
  CandidateSummaryTemplateFragment,
  CandidateSummaryTemplateSectionInput,
  useGenerateCandidateSummaryMutation,
  useUpdateCandidateSummaryTemplateMutation,
} from "../../../graphql";
import { primaryButtonGrayProps } from "../../utils";
import EditTemplateTemplate from "./EditTemplateTemplate";
import { TemplateSections } from "./types";
import { useUpdateSections } from "./useUpdateSections";

const buildInputSections = (
  sections: TemplateSections
): CandidateSummaryTemplateSectionInput[] => {
  if (!sections) return [];
  return sections.map((item) => {
    return {
      title: item.title,
      prompt: item.prompt,
      position: item.position,
      subsections: item.subsections
        ? item.subsections.map((subitem) => {
            return {
              title: subitem.title,
              prompt: subitem.prompt,
              position: subitem.position,
            };
          })
        : [],
    };
  });
};

type EditTemplateProps = {
  template: CandidateSummaryTemplateFragment;
  fromPosition?: CandidateSummaryTemplateFragment["positions"][0];
  fromCandidateId: string;
};

type SubmitType = "save" | "saveAsNew" | null;

const EditTemplate: React.FC<EditTemplateProps> = ({
  template,
  fromPosition,
  fromCandidateId,
}) => {
  const navigate = useNavigate();
  const toast = useToast();

  const [isDefault, setIsDefault] = useState(!!template.isPositionDefault);
  const [name, setName] = useState(template.name);
  const [submitType, setSubmitType] = useState<SubmitType>(null);

  const {
    currentSections,
    handleMoveSection,
    handleMoveSubsection,
    handleTitleChange,
    handlePromptChange,
    addSection,
    addSubsection,
    deleteSection,
    deleteSubsection,
  } = useUpdateSections(template.sections);

  const [generateSummary, { loading: generateLoading }] =
    useGenerateCandidateSummaryMutation();

  const [updateTemplate, { loading }] =
    useUpdateCandidateSummaryTemplateMutation();

  const didTemplateChange =
    JSON.stringify(template.sections) !== JSON.stringify(currentSections);

  const navigateToCandidate = useCallback(
    (candidateId: string, templateId: string, positionId?: string): void => {
      const navigateTo = positionId
        ? `/candidate/${candidateId}/position/${positionId}?tab=interviews&templateId=${templateId}`
        : `/candidate/${candidateId}?tab=interviews&templateId=${templateId}`;
      navigate(navigateTo);
    },
    [navigate]
  );

  const clearAndNavigate = (
    candidateId: string,
    templateId: string,
    positionId?: string
  ): void => {
    setSubmitType(null);
    navigateToCandidate(candidateId, templateId, positionId);
  };

  const handleSave = useCallback(
    (asNewTemplate: boolean): void => {
      setSubmitType(asNewTemplate ? "saveAsNew" : "save");
      if (template) {
        updateTemplate({
          variables: {
            templateId: template.id,
            sections: buildInputSections(currentSections),
            isDefault,
            asNewTemplate,
            positionId: fromPosition?.id,
            newTemplateName: name,
          },
          onError: (err) => {
            toast({
              title: "Updating template error",
              description: err.message,
              status: "error",
            });
          },
          onCompleted: (data) => {
            // if new template, use the new id
            const newTemplateId =
              data.updateCandidateSummaryTemplate?.template.id;
            if (newTemplateId) {
              toast({
                title: "Success",
                description: "Updated the template",
                status: "success",
              });
              if (fromCandidateId) {
                if (didTemplateChange || asNewTemplate) {
                  generateSummary({
                    variables: {
                      candidateId: fromCandidateId,
                      positionId: fromPosition?.id,
                      templateId: newTemplateId,
                    },
                    onError: (err) => {
                      toast({
                        title: "Error",
                        description: `Failed to generate summary: ${err.message}`,
                        status: "error",
                      });
                      clearAndNavigate(
                        fromCandidateId,
                        newTemplateId,
                        fromPosition?.id
                      );
                    },
                    onCompleted: () => {
                      clearAndNavigate(
                        fromCandidateId,
                        newTemplateId,
                        fromPosition?.id
                      );
                    },
                  });
                } else {
                  clearAndNavigate(
                    fromCandidateId,
                    newTemplateId,
                    fromPosition?.id
                  );
                }
              }
            }
          },
        });
      }
    },
    [
      currentSections,
      isDefault,
      fromPosition?.id,
      template?.id,
      updateTemplate,
      fromCandidateId,
      name,
      didTemplateChange,
    ]
  );

  return (
    <>
      <Flex
        h="64px"
        minH="64px"
        alignItems="center"
        justifyContent="space-between"
        backgroundColor="white"
        py={2}
        px={8}
        direction="row"
        borderBottom="1px"
        borderBottomColor="gray.100"
      >
        <Flex direction="row" alignItems="center">
          <IconButton
            aria-label="Back to candidate debrief page"
            icon={<LeftArrow ml={2} mt={3} fontSize="24px" />}
            alignItems="flex-start"
            {...primaryButtonGrayProps}
            onClick={() => navigate(-1)}
          />
          <Box ml="20px">Edit topic coverage template</Box>
          <Flex
            direction="column"
            ml={12}
            height={10}
            justifyContent="flex-start"
          >
            <Text fontSize="10px" color="gray.500" mb={1}>
              Position
            </Text>
            <Text fontSize="sm" color="gray.800">
              {fromPosition?.title || "No position"}
            </Text>
          </Flex>
        </Flex>
        <Flex direction="row" alignItems="center" gap={5}>
          <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <Button
            size="sm"
            variant="outline"
            onClick={() => {
              handleSave(true);
            }}
            isLoading={
              submitType === "saveAsNew" && (loading || generateLoading)
            }
            isDisabled={loading || generateLoading}
          >
            Save as new template
          </Button>
          <Button
            size="sm"
            onClick={() => handleSave(false)}
            isLoading={submitType === "save" && (loading || generateLoading)}
            isDisabled={loading || generateLoading}
          >
            Save
          </Button>
        </Flex>
      </Flex>
      <Flex flexDir="column" alignItems="center" px={8} height="95vh">
        <Flex dir="row" mb="10" mt="20px" maxW="874px" w="100%" height="95vh">
          <Flex
            direction="column"
            flex="1"
            transition="width 0.2s ease"
            border="1px solid"
            backgroundColor="white"
            borderColor="gray.200"
            borderRadius="12px"
            px={4}
            pt={5}
            pb={8}
            overflow="scroll"
            height="95vh"
            maxH="95vh"
          >
            {template && (
              <EditTemplateTemplate
                templateName={name}
                setTemplateName={setName}
                templateId={template.id}
                currentSections={currentSections}
                handleMoveSection={handleMoveSection}
                handleMoveSubsection={handleMoveSubsection}
                handleTitleChange={handleTitleChange}
                handlePromptChange={handlePromptChange}
                addSection={addSection}
                addSubsection={addSubsection}
                deleteSection={deleteSection}
                deleteSubsection={deleteSubsection}
                isDefault={isDefault}
                setIsDefault={setIsDefault}
                positionId={fromPosition?.id}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
export default EditTemplate;
