// cspell:ignore didn
import {
  Box,
  Flex,
  Icon,
  ListItem,
  Spinner,
  Text,
  Tooltip,
  UnorderedList,
} from "@chakra-ui/react";
import LogRocket from "logrocket";
import React, { useCallback, useEffect, useState } from "react";
import { HiOutlineExclamation } from "react-icons/hi";

import {
  Button,
  EmptyAIIcon,
  Link,
  useToast,
} from "../../../../../../components";
import { copy } from "../../../../../../utils/clipboard";
import { formatDuration } from "../../../../../../utils/datetime";
import { useSendGAEvent } from "../../../../../../utils/googleAnalytics";
import {
  AiNotesProcessingStatus,
  CallBetaFragment,
  useCallAiTopicsQuery,
  useCreateAiTopicsMutation,
} from "../../../../../graphql";
import useCurrentUser from "../../../../../hooks/useCurrentUser";
import { noteTimestampedLink } from "../../../../CallNotes/utils";
import MonospacedText from "../../../../MonospacedText";
import AiFeedback from "./NotesQA/AiFeedback";
import AiNotes from "./NotesQA/AiNotes";
import AiNotesLoading from "./NotesQA/AiNotesLoading";
import AiNotesMessage from "./NotesQA/AiNotesMessage";
import useAiNotes from "./NotesQA/useAiNotes";

const withHyphens = (topic: AiTopicsEnum): string =>
  topic.toLowerCase().replace("_", "-");

const withUnderscores = (topic: AiTopicsEnum): string => topic.toLowerCase();

type AiTopicsEnum =
  | "CANDIDATE_QUESTIONS"
  | "COMPENSATION"
  | "NEXT_STEPS"
  | "INTERVIEWER_QUESTIONS";

type AiTopicsProps = {
  call: Pick<
    CallBetaFragment,
    | "id"
    | "canEdit"
    | "candidate"
    | "position"
    | "startTime"
    | "speakers"
    | "transcriptionStatus"
    | "aiTopicsStatuses"
  >;
  onClickTimestamp(t: number): void;
  registerCopyNotes(f: () => void): void;
};

const CATEGORY_BUTTONS: { category: AiTopicsEnum; label: string }[] = [
  { category: "INTERVIEWER_QUESTIONS", label: "Interviewer Qs" },
  { category: "COMPENSATION", label: "Compensation" },
  { category: "NEXT_STEPS", label: "Next steps" },
  { category: "CANDIDATE_QUESTIONS", label: "Candidate Qs" },
];

const TopicContainer: React.FC<{
  data: any[];
  status?: AiNotesProcessingStatus;
  topic: Exclude<AiTopicsEnum, "INTERVIEWER_QUESTIONS">;
}> = ({ status, data, topic }) => {
  if (!status || status === AiNotesProcessingStatus.InProgress) {
    return <AiNotesLoading />;
  }
  if (!data.length) {
    return (
      <AiNotesMessage>
        <EmptyAIIcon mb="5" />
        <Text align="center" maxW="360px">
          We didn&apos;t detect any mentions of this topic in the interview.
        </Text>
      </AiNotesMessage>
    );
  }
  const currentTopic = topic.toLowerCase();
  return (
    <>
      <UnorderedList
        px="6"
        mx="0"
        pt="6"
        pb="70px" // 65px + buffer for the fixed footer
      >
        {data}
      </UnorderedList>
      <AiFeedback feature={`topic-${currentTopic}`} />
    </>
  );
};

const NotesQandAFormat: React.FC<AiTopicsProps> = ({
  call,
  onClickTimestamp,
  registerCopyNotes,
}) => {
  const toast = useToast();
  const sendGAEvent = useSendGAEvent();
  const [currentTopic, setCurrentTopic] = useState<AiTopicsEnum>(
    "INTERVIEWER_QUESTIONS"
  );

  const {
    organization: { includeTimestampsInNoteOutputs },
  } = useCurrentUser();

  const {
    data: aiNotesData,
    copyAiNotes,
    regenerate,
    updateAiNote,
    updateAiNoteQuestion,
    createAiNoteTags,
  } = useAiNotes({ call });

  const {
    loading: aiTopicsLoading,
    error: aiTopicsError,
    data: aiTopicsData,
    stopPolling: stopPollingTopics,
  } = useCallAiTopicsQuery({
    variables: { callId: call.id },
    pollInterval: 2000,
    onCompleted: (data) => {
      const aiTopicsStatuses = data.callAiTopics?.aiTopicsStatuses;
      if (!aiTopicsStatuses || !aiTopicsStatuses.length) {
        createAiTopics({ variables: { callId: call.id } });
      }
    },
  });

  const topicStatuses = aiTopicsData?.callAiTopics?.aiTopicsStatuses;

  // stop polling if all of the topic statuses are either COMPLETED or FAILED
  useEffect(() => {
    if (!topicStatuses || !topicStatuses.length) {
      return;
    }
    const inProgress = topicStatuses.some(
      (o) => o.status === AiNotesProcessingStatus.InProgress
    );
    if (!inProgress) {
      stopPollingTopics();
    }
  }, [stopPollingTopics, topicStatuses]);

  const [createAiTopics] = useCreateAiTopicsMutation({
    onError: (err) => {
      toast({
        title: "AI Topics error",
        description: err.message,
        status: "error",
      });
    },
  });

  const topicItems = aiTopicsData?.callAiTopics?.topics?.find(
    (topic) => topic?.topic === currentTopic
  )?.summary;

  const copyTopicNotes = useCallback(() => {
    if (topicItems && topicItems.length > 0) {
      let plain = "";
      let html = "";
      const topicLabel = CATEGORY_BUTTONS.find(
        (button) => button.category === currentTopic
      )?.label;
      if (topicLabel) {
        plain += `${topicLabel}\n\n`;
        html += `<ul>${topicLabel}<br>`;
      }
      topicItems.forEach((bullet) => {
        plain += `- ${bullet?.text ?? ""}\n`;
        html += `<li>${bullet?.text ?? ""}`;
        if (includeTimestampsInNoteOutputs) {
          html += ` (<a href="${noteTimestampedLink({
            callId: call.id,
            time: bullet?.startTime ?? 0,
          })}">${formatDuration(Math.round(bullet?.startTime ?? 0))}</a>)`;
        }
        html += `</li>`;
      });
      html += "</ul><br>";
      copy({ plain, html });
      toast({
        title: "Notes copied to clipboard",
        status: "success",
      });
      LogRocket.track(`ai-topics-copy-all-${withHyphens(currentTopic)}`);
      sendGAEvent(
        `ai_topics_copy_all_${withUnderscores(currentTopic)}`,
        "call_review"
      );
    }
  }, [topicItems, currentTopic, call, toast]);

  useEffect(() => {
    registerCopyNotes(
      currentTopic === "INTERVIEWER_QUESTIONS" ? copyAiNotes : copyTopicNotes
    );
  }, [currentTopic, registerCopyNotes, copyAiNotes, copyTopicNotes]);

  const topicItemRows = topicItems?.map((item) => (
    <ListItem key={item?.id} color="gray.800" fontSize="sm" mb="4">
      <Flex alignItems="flex-start" justifyContent="space-between">
        <Text mr="4">{item?.text}</Text>
        <Link
          fontWeight="500"
          href={`/interview/${call.id}?t=${item?.startTime ?? ""}`}
          onClick={(e) => {
            e.preventDefault();
            sendGAEvent(
              `ai_topics_seek_${withUnderscores(currentTopic)}`,
              "call_review"
            );
            LogRocket.track("ai-topics-seek");
            onClickTimestamp(item?.startTime ?? 0);
          }}
        >
          <MonospacedText
            text={formatDuration(Math.round(item?.startTime ?? 0))}
          />
        </Link>
      </Flex>
    </ListItem>
  ));

  const isTopicEmpty = useCallback(
    (category: AiTopicsEnum) => {
      return !aiTopicsData?.callAiTopics?.topics.find(
        (topic) => topic?.topic === category
      );
    },
    [aiTopicsData]
  );

  const isTopicDisabled = useCallback(
    (category: AiTopicsEnum) => {
      if (category === "INTERVIEWER_QUESTIONS") {
        return false;
      }
      const status = topicStatuses?.find((o) => o.topic === category);
      if (!status || status.status !== AiNotesProcessingStatus.Completed) {
        return true;
      }
      return isTopicEmpty(category);
    },
    [aiTopicsData, topicStatuses]
  );

  const getStatusIcon = (
    status?: AiNotesProcessingStatus
  ): JSX.Element | null => {
    if (!status || status === AiNotesProcessingStatus.InProgress) {
      return <Spinner size="xs" ml={2} color="gray.200" />;
    }
    if (status === AiNotesProcessingStatus.Failed) {
      return (
        <Icon as={HiOutlineExclamation} ml={2} color="gray.400" size="20" />
      );
    }
    return null;
  };

  const categoryPills = CATEGORY_BUTTONS.map((pill) => {
    const status = topicStatuses?.find(
      (o) => o.topic === pill.category
    )?.status;
    let { label }: { label: string | JSX.Element } = pill;
    if (pill.category !== "INTERVIEWER_QUESTIONS") {
      label = (
        <Flex
          alignItems={
            status === AiNotesProcessingStatus.Failed ? "flex-end" : "center"
          }
        >
          {label} {getStatusIcon(status)}
        </Flex>
      );
    }
    const button = (
      <Button
        key={pill.category}
        variant="pill"
        size="xs"
        my="1"
        mr="2"
        p="0 8px"
        border="1px solid"
        borderColor="gray.200"
        color="gray.800"
        fontSize="xs"
        fontWeight="500"
        isActive={currentTopic === pill.category}
        _active={{
          backgroundColor: "blue.50",
          color: "blue.600",
          borderColor: "blue.400",
          fontWeight: "600",
        }}
        borderRadius="25px"
        onClick={() => {
          setCurrentTopic(pill.category);
          LogRocket.track(`ai-topics-view-${withHyphens(pill.category)}`);
          sendGAEvent(
            `ai_topics_view_${withUnderscores(pill.category)}`,
            "call_review"
          );
        }}
        disabled={isTopicDisabled(pill.category)}
        data-tour-id={`ai-notes-topic-button-${withHyphens(pill.category)}`}
      >
        {label}
      </Button>
    );
    const hasTooltip =
      status === AiNotesProcessingStatus.Failed ||
      (status === AiNotesProcessingStatus.Completed &&
        isTopicEmpty(pill.category));
    let tooltipLabel = "";
    if (status === AiNotesProcessingStatus.Failed) {
      tooltipLabel = "We weren’t able to generate a summary for this topic.";
    } else if (isTopicEmpty(pill.category)) {
      tooltipLabel = "We didn’t detect this topic in the interview.";
    }
    return (
      <React.Fragment key={pill.label}>
        {hasTooltip ? <Tooltip label={tooltipLabel}>{button}</Tooltip> : button}
      </React.Fragment>
    );
  });

  const topicsReady = !aiTopicsLoading && !aiTopicsError && topicStatuses;
  const interviewQPill = categoryPills[0];
  const remainingTopicPills = categoryPills.slice(1);

  return (
    <Box>
      <Flex
        justifyContent="space-between"
        data-tour-id="ai-notes-topic-buttons-container"
      >
        <Flex
          alignItems="center"
          flexWrap="wrap"
          data-tour-id="ai-notes-topic-buttons"
          mb="12px"
        >
          {interviewQPill}
          {topicsReady && remainingTopicPills}
          {!topicsReady && (
            <>
              {aiTopicsError ? (
                <Box mr="2" fontSize="sm" color="yellow.800">
                  Unable to generate AI Topics. Try again later.
                </Box>
              ) : (
                <>
                  <Box mr="2" fontSize="sm" color="gray.400">
                    Generating interview topics...
                  </Box>
                  <Spinner size="sm" color="gray.200" />
                </>
              )}
            </>
          )}
        </Flex>
      </Flex>
      {currentTopic === "INTERVIEWER_QUESTIONS" ? (
        <AiNotes
          call={call}
          data={aiNotesData}
          copyAiNotes={copyAiNotes}
          regenerate={regenerate}
          updateAiNote={updateAiNote}
          updateAiNoteQuestion={updateAiNoteQuestion}
          onClickTimestamp={onClickTimestamp}
          createAiNoteTags={createAiNoteTags}
        />
      ) : (
        <TopicContainer
          status={
            topicStatuses?.find((o) => o.topic === currentTopic)?.status ??
            undefined
          }
          data={topicItemRows ?? []}
          topic={currentTopic}
        />
      )}
    </Box>
  );
};

export default NotesQandAFormat;
