import { Box, Checkbox, Tooltip } from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";

import {
  Button,
  errorToast,
  FormControl,
  successToast,
  useToast,
} from "../../../components";
import { CurrentUserFragment } from "../../graphql";
import useUpdateCurrentUser from "../../graphql/hooks/useUpdateCurrentUser";
import SettingsPageContainer from "./shared/SettingsPageContainer";

interface UserScorecardSettingsProps {
  currentUser: CurrentUserFragment;
}

type FormValues = {
  autofillGreenhouseScorecard: boolean;
};

export const UserScorecardSettings: React.FC<UserScorecardSettingsProps> = ({
  currentUser,
}) => {
  const toast = useToast();
  const [updateCurrentUser, { loading }] = useUpdateCurrentUser({
    onCompleted: () => {
      successToast(toast, "Settings saved");
    },
    onError: () => {
      errorToast(toast, "There was a problem updating your settings");
    },
  });

  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      autofillGreenhouseScorecard: currentUser.autofillGreenhouseScorecard,
    },
  });

  const forcedOn =
    currentUser.organization.requiresGreenhouseScorecardAutoDraft;
  return (
    <SettingsPageContainer
      heading="Scorecard Settings"
      subHeading="Choose how to manage your scorecards with BrightHire"
    >
      <form
        onSubmit={handleSubmit((formData) => {
          updateCurrentUser({ variables: formData });
        })}
      >
        <Tooltip
          placement="bottom-start"
          ml="4"
          mt="-2"
          label={
            forcedOn
              ? "Your administrator has required this to be enabled for all BrightHire users"
              : undefined
          }
        >
          <FormControl
            isDisabled={forcedOn}
            helpText={
              <Box fontSize="sm" lineHeight="5">
                BrightHire will automatically create a draft of your scorecard
                in Greenhouse with notes from your interview. The scorecard{" "}
                <strong>will not</strong> be automatically submitted
              </Box>
            }
          >
            <Checkbox
              {...register("autofillGreenhouseScorecard")}
              defaultChecked={currentUser.autofillGreenhouseScorecard}
            >
              Automatically draft Greenhouse scorecards
            </Checkbox>
          </FormControl>
        </Tooltip>
        <Button type="submit" mt="12" isLoading={loading} isDisabled={forcedOn}>
          Save
        </Button>
      </form>
    </SettingsPageContainer>
  );
};
