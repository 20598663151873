import {
  Box,
  Center,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React from "react";

import { Alert, Link } from "../../../../components";
import { useSkillReportsQuery } from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";
import SkillsReportHeader from "./SkillsReportHeader";
import SkillsReportLoading from "./SkillsReportLoading";
import SkillsReportTable from "./SkillsReportTable";

const AnalyticsSkillsReports: React.FC = () => {
  const currentUser = useCurrentUser();
  const { loading, error, data } = useSkillReportsQuery();

  if (loading) {
    return <SkillsReportLoading />;
  }

  if (error) {
    return <Alert status="error" description="Error loading report" reload />;
  }

  const reports = data?.currentUser?.organization.skillReports || [];
  const myReports = reports.filter(
    (report) => report.creatorId === currentUser.id
  );

  const emptyState = (
    <Center mt="3">
      <Text>
        No saved reports. Create a new one{" "}
        <Link href="/insights/skill-report">here</Link>.
      </Text>
    </Center>
  );

  return (
    <Box pb="100px">
      <SkillsReportHeader includeBuild />
      <Box p="8">
        <Tabs>
          <TabList>
            <Tab>My reports ({myReports.length})</Tab>
            <Tab>All reports ({reports.length})</Tab>
          </TabList>
          <TabPanels mt="2">
            <TabPanel>
              {myReports.length === 0 ? (
                emptyState
              ) : (
                <SkillsReportTable reports={myReports} />
              )}
            </TabPanel>
            <TabPanel>
              {reports.length === 0 ? (
                emptyState
              ) : (
                <SkillsReportTable reports={reports} />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};

export default AnalyticsSkillsReports;
