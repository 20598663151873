import { Box, Input, Tag, TagCloseButton, TagLabel } from "@chakra-ui/react";
import React, { forwardRef, Ref, useState } from "react";

interface KeywordsInputProps {
  keywords: string[];
  onChange: (keywords: string[]) => void;
}

const KeywordsInput = forwardRef<HTMLInputElement, KeywordsInputProps>(
  ({ keywords, onChange }, ref: Ref<HTMLInputElement>) => {
    const [inputValue, setInputValue] = useState("");

    const handleKeyDown = (
      event: React.KeyboardEvent<HTMLInputElement>
    ): void => {
      const lowercasedValue = inputValue.trim().toLowerCase();
      if (event.key === "Enter" && lowercasedValue) {
        setInputValue("");
        if (keywords.includes(lowercasedValue)) {
          return;
        }
        const newList = [...keywords, lowercasedValue];
        onChange(newList);
      }
    };

    const handleRemoveKeyword = (keyword: string): void => {
      const newList = keywords.filter((k) => k !== keyword);
      onChange(newList);
    };

    return (
      <Box
        backgroundColor="white"
        borderWidth={keywords.length ? "1px" : "0"}
        borderColor="gray.200"
        borderRadius="6px"
        role="region"
        aria-label="Keywords input"
      >
        {!!keywords.length && (
          <Box mb={2} px={3}>
            {keywords.map((keyword) => (
              <Tag
                key={keyword}
                size="md"
                variant="solid"
                backgroundColor="blue.50"
                color="blue.700"
                fontWeight="400"
                borderRadius="4px"
                mr={2}
                mt={2}
                role="listitem"
              >
                <TagLabel aria-label={`Keyword: ${keyword}`}>
                  {keyword}
                </TagLabel>
                <TagCloseButton onClick={() => handleRemoveKeyword(keyword)} />
              </Tag>
            ))}
          </Box>
        )}
        <Input
          ref={ref}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Type a keyword and press Enter"
          borderColor={keywords.length ? "transparent" : "gray.200"}
          borderBottomLeftRadius="6px"
          borderBottomRightRadius="6px"
          borderTopLeftRadius={keywords.length ? "0" : "6px"}
          borderTopRightRadius={keywords.length ? "0" : "6px"}
          _hover={{
            borderColor: keywords.length ? "transparent" : "gray.200",
          }}
          _placeholder={{
            color: "gray.400",
          }}
        />
      </Box>
    );
  }
);

export default KeywordsInput;
