import {
  Box,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  Select,
  Spinner,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import {
  Button,
  Link,
  RouterLink,
  successToast,
  useToast,
} from "../../../../components";
import { isCustomError } from "../../../../utils/apollo/error";
import {
  OrgScorecardAutoDraftOnboardingRule,
  OrgScorecardAutoDraftRule,
  useUpdateOrganizationMutation,
} from "../../../graphql";
import useCurrentUser from "../../../hooks/useCurrentUser";
import SettingsNote from "../shared/SettingsNote";

export const ScorecardAutoDraftSettings: React.FC = () => {
  const toast = useToast();

  const currentUser = useCurrentUser();

  const [updateOrg, { loading }] = useUpdateOrganizationMutation({
    onCompleted: () =>
      successToast(toast, "Scorecard auto draft settings saved"),
    onError: (e) => {
      setError("greenhouseScorecardAutoDraftRule", {
        message: isCustomError(e)
          ? e.message
          : "There was a problem saving scorecard auto draft settings",
      });
    },
  });

  const {
    greenhouseScorecardAutoDraftRule,
    greenhouseScorecardAutoDraftOnboardingRule,
  } = currentUser.organization;

  const {
    setError,
    watch,
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      greenhouseScorecardAutoDraftRule,
      greenhouseScorecardAutoDraftOnboardingRule,
    },
  });

  const autoDraftRuleValue = watch("greenhouseScorecardAutoDraftRule");
  const autoDraftOnboardingRuleDisabled = [
    OrgScorecardAutoDraftRule.Disabled,
    OrgScorecardAutoDraftRule.ForcedEnabled,
  ].includes(autoDraftRuleValue);

  return (
    <Box
      as="form"
      onSubmit={handleSubmit((autoDraftSettings) =>
        updateOrg({ variables: autoDraftSettings })
      )}
    >
      <Divider mt="8" mb="6" />
      <Text fontSize="lg" fontWeight="semibold" mb="2">
        Scorecard auto draft
      </Text>
      <Text mb="4" fontSize="sm">
        BrightHire can automatically create a draft scorecard in Greenhouse
        after an interview is complete. Drafts will include notes from the
        interview and will not be automatically submitted. You can learn more{" "}
        <Link
          href="https://help.brighthire.ai/en/articles/10000464-greenhouse-scorecard-auto-draft"
          target="_blank"
        >
          here
        </Link>
      </Text>
      <Flex direction="column" gap="8">
        <FormControl id="greenhouseScorecardAutoDraftRule">
          <Select
            {...register("greenhouseScorecardAutoDraftRule")}
            defaultValue={greenhouseScorecardAutoDraftRule}
            isDisabled={loading}
          >
            <option value={OrgScorecardAutoDraftRule.Disabled}>Disabled</option>
            <option value={OrgScorecardAutoDraftRule.DefaultDisabled}>
              Enabled, default to off
            </option>
            <option value={OrgScorecardAutoDraftRule.DefaultEnabled}>
              Enabled, default to on
            </option>
            <option value={OrgScorecardAutoDraftRule.ForcedEnabled}>
              Force enabled for all users
            </option>
          </Select>
          {errors.greenhouseScorecardAutoDraftRule ? (
            <SettingsNote mt="4" variant="error">
              {errors.greenhouseScorecardAutoDraftRule.message}
            </SettingsNote>
          ) : loading ? (
            <Flex mt="8" mb="4" justify="center">
              <Spinner />
            </Flex>
          ) : (
            <SettingsNote mt="4">
              {autoDraftRuleValue === OrgScorecardAutoDraftRule.Disabled && (
                <>
                  Auto draft will be disabled for all users. The feature will
                  not be shown in user settings
                </>
              )}
              {autoDraftRuleValue ===
                OrgScorecardAutoDraftRule.DefaultDisabled && (
                <>
                  Auto draft is enabled and users can select their preference,
                  with the default being to <strong>not create</strong>{" "}
                  scorecard drafts
                </>
              )}
              {autoDraftRuleValue ===
                OrgScorecardAutoDraftRule.DefaultEnabled && (
                <>
                  Auto draft is enabled and users can select their preference,
                  with the default being <strong>to create</strong> scorecard
                  drafts
                </>
              )}
              {autoDraftRuleValue ===
                OrgScorecardAutoDraftRule.ForcedEnabled && (
                <>
                  Auto draft is enabled for all users, and users{" "}
                  <strong>cannot</strong> change this behavior in their settings
                </>
              )}
            </SettingsNote>
          )}
        </FormControl>
        <FormControl
          id="greenhouseScorecardAutoDraftOnboardingRule"
          hidden={autoDraftOnboardingRuleDisabled}
        >
          <Controller
            control={control}
            name="greenhouseScorecardAutoDraftOnboardingRule"
            render={({ field }) => (
              <Checkbox
                isChecked={
                  field.value === OrgScorecardAutoDraftOnboardingRule.Show
                }
                onChange={(e) =>
                  field.onChange(
                    e.target.checked
                      ? OrgScorecardAutoDraftOnboardingRule.Show
                      : OrgScorecardAutoDraftOnboardingRule.Hide
                  )
                }
              >
                Require during user registration
              </Checkbox>
            )}
          />
          <FormHelperText>
            This will force new and existing users to choose their auto draft
            preference the next time they log in to BrightHire.
            <br />
            <br />
            <strong>Note: </strong>Users may update their choice at any time in
            their{" "}
            <RouterLink to="/settings/user/scorecard" target="_blank">
              settings page.
            </RouterLink>
          </FormHelperText>
        </FormControl>
      </Flex>
      <Button type="submit" mt="8" isLoading={loading}>
        Save scorecard auto draft settings
      </Button>
    </Box>
  );
};
