import { Box, Flex, Image, Text } from "@chakra-ui/react";
import LogRocket from "logrocket";
import React, { useEffect, useState } from "react";

import { Button, LoadingIndicator, useToast } from "../../../components";
import { useBooleanSearchParam } from "../../../hooks/useSearchParam";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { usePageTracker } from "../../../utils/googleAnalytics";
import ExternalRecordingView from "../../components/Interview/ExternalRecording/ExternalRecordingViewBeta";
import {
  useExternalShareViewQuery,
  useExternalUserRequestAccessCodeMutation,
  useExternalUserRequestShareRenewalMutation,
  useVerifyExternalUserAccessCodeMutation,
} from "../../graphql";
import { ExternalSharePageHeader } from "./ExternalSharePageHeader";
import { CodeVerificationResponse } from "./types";
import { useExternalShareParams } from "./useExternalShareParams";
import { VerifyAccessCodeForm } from "./VerifyAccessCodeForm";

type ExternalSharePageState =
  | "requestCode"
  | "verifyCode"
  | "renewAccess"
  | "forbidden"
  | "shareNotFound"
  | "recordingNotFound"
  | "authenticated";

const PAGE_STATE_DESCRIPTIONS: Record<ExternalSharePageState, any> = {
  requestCode: {
    header: "To view this recording, please verify your email address below.",
    subHeader: "We will send a one-time access code to verify your email.",
  },
  verifyCode: {
    header: "Please enter the access code from your email.",
    subHeader:
      "An email was sent containing your one-time access code. Please remember to check your spam and promotional folders.",
  },
  renewAccess: {
    header: "Your access to this recording has expired.",
    subHeader:
      "You can request access again from the person who shared it with you.",
  },
  recordingNotFound: {
    header: "This interview was not found.",
    subHeader: "It may have been deleted from BrightHire.",
  },
  shareNotFound: {
    header: "We can't seem to find the page you're looking for.",
  },
  forbidden: {},
  authenticated: {},
};

const ExternalSharePage: React.FC = () => {
  const { percentHeight, windowHeight } = useWindowDimensions();

  usePageTracker("external_share");
  const toast = useToast();
  const { shareId, shareType, index } = useExternalShareParams();
  const [codeSent, setCodeSent] = useBooleanSearchParam("codeSent");
  const [pageState, setPageState] = useState<ExternalSharePageState>(
    codeSent ? "verifyCode" : "requestCode"
  );
  const [codeVerificationResponse, setCodeVerificationResponse] =
    useState<CodeVerificationResponse>("valid");

  const [requestShareRenewal] = useExternalUserRequestShareRenewalMutation({
    onCompleted: (data) => {
      if (data.externalUserRequestShareRenewal?.requestSent) {
        toast({
          title: `Request sent`,
          description:
            "You'll receive an email when you have been granted access again to this recording.",
          status: "success",
        });
      }
    },
    onError: (error) => {
      toast({
        title: `Error`,
        description: error.message,
        status: "error",
      });
    },
  });

  const [requestAccessCode] = useExternalUserRequestAccessCodeMutation({
    onCompleted: (data) => {
      if (data.externalUserRequestAccessCode?.verificationSent) {
        toast({
          title: `Email sent`,
          description:
            "Please remember to check your spam and promotional folders.",
          status: "success",
        });
        setCodeSent(true);
        setPageState("verifyCode");
      }
    },
    onError: (error) => {
      toast({
        title: `Error`,
        description: error.message,
        status: "error",
      });
    },
  });

  const [verifyAccessCode] = useVerifyExternalUserAccessCodeMutation({
    onCompleted: () => {
      setCodeVerificationResponse("valid");
      refetchExternalShare();
      setPageState("authenticated");
      setCodeSent(undefined);
    },
    onError: (e) => {
      let response: CodeVerificationResponse = "invalid";
      if (e?.graphQLErrors.length) {
        const errorMessages = e?.graphQLErrors.map((e) => e.message);
        response = errorMessages.length
          ? (errorMessages[0] as CodeVerificationResponse)
          : "invalid";
      }
      setCodeVerificationResponse(response);
    },
  });

  const {
    data,
    previousData,
    loading: externalShareLoading,
    error,
    refetch: refetchExternalShare,
  } = useExternalShareViewQuery({
    variables: { id: shareId, shareType, index },
    pollInterval: 10 * 60 * 1000, // 10m
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    let newState: ExternalSharePageState = "authenticated";
    if (error?.graphQLErrors.length) {
      const errorCodes = error?.graphQLErrors.map((e) => e.extensions?.code);
      // External share cannot be found (likely removed after the fact)
      if (errorCodes.some((code) => code === "NOT_FOUND")) {
        newState = "shareNotFound";
      } else if (errorCodes.some((code) => code === "SHARE_EXPIRED")) {
        newState = "renewAccess";
      } else if (errorCodes.some((code) => code === "UNAUTHENTICATED")) {
        newState = codeSent ? "verifyCode" : "requestCode";
      } else if (errorCodes.some((code) => code === "FORBIDDEN")) {
        // external user accessing the external share is not the external user the share was shared with
        newState = "requestCode";
      } else if (errorCodes.some((code) => code === "RECORDING_NOT_FOUND")) {
        newState = "recordingNotFound";
      }
    }
    if (newState === "authenticated") {
      LogRocket.track("external-share-view");
    }
    setPageState(newState);
  }, [error]);

  if (pageState !== "authenticated") {
    const description = PAGE_STATE_DESCRIPTIONS[pageState];
    let inputForPageState = null;
    let heroSource = "/static/images/external_share_hero.png";
    if (pageState === "requestCode") {
      inputForPageState = (
        <Button
          fontWeight="500"
          fontSize="sm"
          textTransform="none"
          px="5"
          onClick={() => {
            requestAccessCode({ variables: { externalShareId: shareId } });
            LogRocket.track("external-share-request-access-code");
          }}
        >
          Email me an access code
        </Button>
      );
    } else if (pageState === "verifyCode") {
      inputForPageState = (
        <VerifyAccessCodeForm
          onVerify={(verificationCode) =>
            verifyAccessCode({
              variables: { externalShareId: shareId, verificationCode },
            })
          }
          onRequest={() => {
            requestAccessCode({ variables: { externalShareId: shareId } });
            LogRocket.track("external-share-request-access-code");
          }}
          codeVerificationResponse={codeVerificationResponse}
        />
      );
    } else if (pageState === "renewAccess") {
      inputForPageState = (
        <Button
          fontWeight="500"
          fontSize="sm"
          textTransform="none"
          px="5"
          onClick={() =>
            requestShareRenewal({ variables: { externalShareId: shareId } })
          }
        >
          Request access
        </Button>
      );
    } else if (
      pageState === "recordingNotFound" ||
      pageState === "shareNotFound"
    ) {
      heroSource = "/static/images/external_share_hero_not_found.png";
    }
    return (
      <Flex
        flexDir="column"
        minHeight={windowHeight}
        backgroundColor="white"
        pb={{ base: "8", sm: "0" }}
      >
        <ExternalSharePageHeader />
        <Box
          backgroundImage="/static/images/external_share_background_cropped.png"
          backgroundSize="100%"
          backgroundRepeat="no-repeat"
          width="100%"
          textAlign="center"
          px={{
            base: "5",
            sm: "0",
          }}
        >
          <Image
            src={heroSource}
            mx="auto"
            mt={{ base: "6", sm: "10", xl: "20" }}
          />
          <Text
            color="gray.900"
            opacity="0.9"
            fontSize="2xl"
            fontWeight="500"
            mb="5"
          >
            {description.header}
          </Text>
          <Text
            color="gray.800"
            opacity="0.8"
            fontSize="md"
            fontWeight="400"
            mb="12"
            maxWidth="600px"
            mx="auto"
            lineHeight="150%"
          >
            {description.subHeader}
          </Text>
          {inputForPageState}
        </Box>
      </Flex>
    );
  }

  const shareData = data?.externalShareView ?? previousData?.externalShareView;
  const recordingData = shareData?.externalRecording;

  return (
    <Flex
      flexDir="column"
      height={windowHeight}
      backgroundColor="pageBackground"
    >
      <ExternalSharePageHeader
        sharedByEmail={shareData?.sharedByEmail}
        shareDaysRemaining={shareData?.shareDaysRemaining}
        playlistNavigationInfo={shareData?.playlistNavigationInfo}
      />
      {recordingData ? (
        <ExternalRecordingView
          recording={recordingData}
          isClip={shareType === "clip"}
          loading={externalShareLoading}
        />
      ) : externalShareLoading ? (
        <LoadingIndicator mt={percentHeight(25)} />
      ) : null}
    </Flex>
  );
};

ExternalSharePage.displayName = "ExternalSharePage";
export default ExternalSharePage;
